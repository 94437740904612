.privateAlert {
  max-width: 1000px;
  margin: 24px auto 16px
}

.notFoundMember {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}
