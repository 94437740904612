.headerLink .headerBtn {
  border: none;
  color: var(--purple-6);
  padding: 4px 8px;
  margin: 0 5px;
}
.headerLink .headerBtn:hover, .headerLink .headerBtn:focus, .headerLink .headerBtn:active, .headerLink .headerBtn.active {
  background-color: var(--purple-1);
  border: none;
  color: var(--purple-6);
}
.headerLink .headerBtn:focus-visible {
  background-color: var(--purple-1);
  border: 1px solid var(--purple-7);
  color: var(--purple-7);
  box-shadow: 0 0 0 2px var(--gray-1), 0 0 0 4px var(--purple-7);
}
