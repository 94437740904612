.collaboratorCard {
  background-color: var(--purple-6);
  background: var(--purple-6);
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  height: 344px;
  justify-content: space-between;
}

.collaboratorCard.alt {
  background: var(--primary-gradient);
  background: radial-gradient(
      50% 50% at 50% 50%,
      var(--primary-gradient) 0%,
      rgba(34, 43, 92, 0.6) 100%
    ),
    var(--primary-gradient);
  background-repeat: no-repeat;
}

.collaboratorCard .header {
  display: flex;
  align-items: center;
  gap: 16px;
}


.collaboratorCard .title {
  color: var(--gray-1);
  font-family: Rubik;
  font-size: 24px;
  font-weight: 600;
  line-height: 133.4%; /* 32.016px */
  margin: 0;
}

@media (max-width: 1200px) {
  .collaboratorCard {
    height: 392px;
    max-width: 358px;
    width: 100%;
  }

  .collaboratorCard img {
    max-width: 280px;
  }

  .collaboratorCard button {
    justify-content: center;
    width: 100%;
  }
}

@media (max-width: 900px) {
  .collaboratorCard {
    align-items: center;
    max-width: 100%;
    height: 296px;
    width: 100%;
  }
}
