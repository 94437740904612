:root{
	--gray-1: #ffffff;
	--gray-2: #f8f8fc;
	--gray-3: #f0f1f8;
	--gray-4: #dddfee;
	--gray-5: #b9bcd4;
	--gray-6: #8a8da8;
	--gray-7: #6e7190;
	--gray-8: #383f72;
	--gray-9: #282d52;
	--gray-10: #1e223e;
	--blue-1: #e6fcff;
	--blue-2: #a3f3ff;
	--blue-3: #7ae9ff;
	--blue-4: #52dcff;
	--blue-5: #28c7f7;
	--blue-6: #00aceb;
	--blue-7: #0089c4;
	--blue-8: #00699e;
	--blue-9: #004c78;
	--blue-10: #003152;
	--red-1: #fff1f0;
	--red-2: #ffccc7;
	--red-3: #ffa39e;
	--red-4: #f77472;
	--red-5: #eb4649;
	--red-6: #dd1f2a;
	--red-7: #b8111e;
	--red-8: #910616;
	--red-9: #6b0010;
	--red-10: #45000d;
	--volcano-1: #fff2e8;
	--volcano-2: #ffd8bf;
	--volcano-3: #ffbb96;
	--volcano-4: #ff9c6e;
	--volcano-5: #ff7a45;
	--volcano-6: #fa541c;
	--volcano-8: #d4380d;
	--volcano-8: #ad2102;
	--volcano-9: #871400;
	--volcano-10: #610b00;
	--orange-1: #fffaf0;
	--orange-2: #ffebc7;
	--orange-3: #ffda9e;
	--orange-4: #ffc675;
	--orange-5: #ffaf4d;
	--orange-6: #f79122;
	--orange-7: #d16f13;
	--orange-8: #ab5107;
	--orange-9: #853700;
	--orange-10: #5e2400;
	--gold-1: #fffbe6;
	--gold-2: #fff1b8;
	--gold-3: #ffe58f;
	--gold-4: #ffd666;
	--gold-5: #ffc53d;
	--gold-6: #faad14;
	--gold-7: #d48806;
	--gold-8: #ad6800;
	--gold-9: #874d00;
	--gold-10: #613400;
	--yellow-1: #feffe6;
	--yellow-2: #ffffb8;
	--yellow-3: #fffb8f;
	--yellow-4: #fff566;
	--yellow-5: #ffec3d;
	--yellow-6: #fadb14;
	--yellow-7: #d4b106;
	--yellow-8: #ad8b00;
	--yellow-9: #876800;
	--yellow-10: #614700;
	--lime-1: #fcffe6;
	--lime-2: #f4ffb8;
	--lime-3: #eaff8f;
	--lime-4: #d3f261;
	--lime-5: #bae637;
	--lime-6: #a0d911;
	--lime-7: #7cb305;
	--lime-8: #5b8c00;
	--lime-9: #3f6600;
	--lime-10: #254000;
	--green-1: #f6ffed;
	--green-2: #d9f7be;
	--green-3: #b7eb8e;
	--green-4: #95de64;
	--green-5: #73d13d;
	--green-6: #52c41a;
	--green-7: #389e0d;
	--green-8: #237804;
	--green-9: #135200;
	--green-10: #092b00;
	--cyan-1: #ddf4f8;
	--cyan-2: #c1e6ec;
	--cyan-3: #aae0e9;
	--cyan-4: #86d6df;
	--cyan-5: #33b8cc;
	--cyan-6: #009bba;
	--cyan-7: #007694;
	--cyan-7-r: 0;
	--cyan-7-g: 118;
	--cyan-7-b: 148;
	--cyan-8: #00546e;
	--cyan-9: #003447;
	--cyan-9-r: 0;
	--cyan-9-g: 52;
	--cyan-9-b: 71;
	--cyan-10: #001721;
	--geekblue-1: #ebedf9;
	--geekblue-2: #c5cbe8;
	--geekblue-3: #a0aad8;
	--geekblue-4: #7b88c8;
	--geekblue-5: #6476bd;
	--geekblue-6: #4254b1;
	--geekblue-7: #2b388f;
	--geekblue-8: #1b2263;
	--geekblue-9: #0d113d;
	--geekblue-10: #050517;
	--purple-1: #f5e5f1;
	--purple-2: #e6bddc;
	--purple-3: #d592c5;
	--purple-4: #c367af;
	--purple-5: #b5469e;
	--purple-6: #a6278f;
	--purple-7: #801770;
	--purple-8: #590c50;
	--purple-9: #33042f;
	--purple-10: #0d010c;
	--magenta-1: #fff0f5;
	--magenta-2: #ffe3ee;
	--magenta-3: #ffbad8;
	--magenta-4: #ff91c5;
	--magenta-5: #f263ab;
	--magenta-6: #e53a95;
	--magenta-7: #bf267d;
	--magenta-8: #991765;
	--magenta-9: #730b4d;
	--magenta-10: #4d0735;


	--primary-gradient: #2C3689;
	--secondary-gradient-1: #EC2786;
	--secondary-gradient-2: #B63E97;
	--secondary-gradient-3: #765AAB;
	--secondary-gradient-4: #4371BC;
	--secondary-gradient-5: #1E81C8;
	--secondary-gradient-6: #088BCF;
	--secondary-gradient-7: #008FD2;
	--purple-gradient-1: #A1186A;
	--purple-gradient-2: #573F8C;
	--purple-gradient-3: #52418C;
	--purple-gradient-4: #1B5B8E;

	/* Colors: */
	--preset-colors: pink, magenta, red, volcano, orange, yellow, gold, cyan, lime, green, blue, geekblue, purple;

}