.statsCharts {
  display: flex;
  flex-grow: 1;
  padding-top: 80px;
  gap: 40px;
  width: 100%;
}

@media (max-width: 900px) {
  .statsCharts {
    flex-direction: column;
  }
}
