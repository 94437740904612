.tooltip {
  border-bottom: 1px dotted;
}

.link {
  text-decoration: underline;
}
.link:hover {
  text-decoration: none;
}


