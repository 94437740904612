.dataFilesTabWrapper {
  display: flex;
}
.dataFilesTabWrapper :global(.ant-pagination) {
  margin-bottom: 0;
}
.dataFilesTabWrapper .unauthorizedLock {
  color: var(--gray-6);
}
.dataFilesTabWrapper .authorizedLock {
  color: var(--green-7);
}
.dataFilesTabWrapper .authorizedUndetermined {
  color: var(--orange-7);
}