/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400 600 900;
  font-display: swap;
  src: local('Rubik'), url(./fonts/Rubik.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400 600 900;
  font-display: swap;
  src: local('Source Sans Pro'), url(./fonts/SourceSansPro.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root {
  --font-weight-semibold: 500;
  --font-weight-bold: 600;
  --heading-color: var(--geekblue-7);
  --query-default-action-default-color: var(--gray-8);
  --query-default-action-hover-color: var(--gray-8);
  --query-default-action-focus-color: var(--gray-8);
  --query-builder-header-tools-icon-color: var(--cyan-7);
  --query-builder-header-tools-icon-hover: var(--cyan-7);
  --query-builder-header-tools-icon-focus: var(--cyan-7);
  --query-builder-header-tools-icon-color-dirty: var(--orange-7);
  --query-builder-bg: var(--gray-1);
  --query-builder-border-color: var(--gray-4);
  --query-builder-header-border-divider-color: var(--gray-4);
  --query-bar-bg: var(--gray-2);
  --query-bar-bg-active: var(--cyan-1);
  --query-bar-border-color: var(--gray-4);
  --query-bar-border-color-active: var(--cyan-3);
  --query-bar-identifier: var(--gray-6);
  --query-bar-identifier-active: var(--cyan-6);
  --query-pill-bg: var(--gray-4);
  --query-pill-bg-active: var(--cyan-2);
  --query-pill-button-default-color: var(--query-default-action-default-color);
  --query-pill-button-hover-color: var(--query-default-action-hover-color);
  --query-pill-button-focus-color: var(--query-default-action-hover-color);
  --query-icon-operator-color: var(--gray-8);
  --query-text-operator-color-hover: var(--gray-8);
  --query-icon-operator-color-hover: var(--gray-8);
  --query-icon-label-operator-color: var(--gray-8);
  --query-combiner-button-default-color: var(--query-default-action-default-color);
  --query-combiner-button-hover-color: var(--query-default-action-hover-color);
  --query-combiner-button-focus-color: var(--query-default-action-hover-color);
  --checkbox-filter-actions-separator-links: var(--blue-8);
  --filter-actions-border-color: var(--gray-4);
  --toggle-filter-sc-button-color: var(--cyan-7);
  --sidebar-login-width: 573px;
  --sidebar-menu-width: 180px;
  --sidebar-menu-collapsed-width: 50px;
  --sidebar-menu-background-color: var(--cyan-7);
  --sidebar-menu-toggle-icon-size: 21px;
  --sidebar-menu-item-icon-size: 22px;
  --sidebar-menu-item-height: 50px;
  --sidebar-menu-item-text-color: var(--gray-1);
  --sidebar-menu-item-active-background-color: var(--cyan-8);
  --sidebar-menu-item-active-color: var(--gray-1);
  --sidebar-menu-item-hover-color: var(--gray-1);
  --sidebar-menu-item-hover-opacity: 0.8;
  --sidebar-menu-item-border-focus: none;
  --sidebar-menu-item-border-focus-color: var(--gray-1);
  --sidebar-quick-filter-input-box-shadow-hover: 0 0 0 2px var(--cyan-8);
  --sidebar-quick-filter-input-box-shadow-focus: 0 0 0 2px var(--cyan-7), 0 0 0 3px var(--gray-1);
  --sidebar-quick-filter-input-placeholder-color: var(--gray-6);
  --sidebar-quick-filter-input-text-color: var(--gray-8);
  --sidebar-quick-filter-input-border-color-focus: var(--blue-8);
  --sidebar-quick-filter-higlight-color: var(--cyan-2);
  --sidebar-content-panel-padding: 16px;
  --sidebar-content-panel-width: 320px;
  --sidebar-content-panel-close-icon-color: var(--gray-8);
  --sidebar-content-panel-right-border: 1px solid var(--gray-5);
  --sidebar-content-panel-background-color: transparent;
  --sidebar-content-panel-close-icon-width: 16px;
  --grid-card-light-background-color: white;
  --grid-card-shade-background-color: var(--gray-2);
  --empty-image-color: var(--geekblue-2); 
  --empty-content-color: var(--gray-6);
  --collapse-light-background-color: white;
  --collapse-shade-background-color: var(--gray-3);
  --collapse-header-border-color: var(--gray-4);
  --prolabel-required-mark-color: var(--red-8);
  --file-upload-item-color: var(--blue-8);
  --data-release-icon-font-size: 20px;
  --canonical-icon-color: var(--cyan-7);
  --summary-bg-color: var(--gray-3);
  --maintenance-page-bg: var(--gray-3);
  --maintenance-page-title: var(--geekblue-7);
  --maintenance-page-subtitle: var(--gray-8);
  --color-tag-default-bg: var(--gray-4);
  --color-tag-default-color: var(--gray-8);
  --color-tag-boolean-true-bg: var(--green-2);
  --color-tag-boolean-true-color: var(--green-8);
  --color-tag-gender-female-bg: var(--magenta-2);
  --color-tag-gender-female-color: var(--magenta-8);
  --color-tag-gender-male-bg: var(--blue-2);
  --color-tag-gender-male-color: var(--blue-9);
  --color-tag-family-bg: var(--cyan-2);
  --color-tag-family-color: var(--cyan-8);
  --color-tag-position-proband-bg: var(--purple-2);
  --color-tag-position-proband-color: var(--purple-8);
  --color-tag-vital-status-alive-bg: var(--green-2);
  --color-tag-vital-status-alive-color: var(--green-8);
  --color-tag-vital-status-deceased-bg: var(--red-2);
  --color-tag-vital-status-deceased-color: var(--red-8);
  --color-tag-interpretation-observed-bg: var(--green-2);
  --color-tag-interpretation-observed-color: var(--green-8);
  --color-tag-pathogenic-bg: var(--red-2);
  --color-tag-pathogenic-color: var(--red-8);
  --color-tag-benign-bg: var(--green-2);
  --color-tag-benign-color: var(--green-8);
  --chart-title-color: var(--geekblue-7);
  --default-padding: 24px;
  --default-page-content-padding: 16px 24px;
  --main-header-height: 64px;
  --community-profile-banner-btn: var(--cyan-4);
}

html {
  font-size: 12px;
}
