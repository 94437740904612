.gradientAccent {
  width: 100%;
  height: 5px;
  background-image: linear-gradient(to right, #90278e, #cc3399 35%, #be1e2d 66%, #f6921e);
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
}
