.landingPageButton {
  border-radius: 100px;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
  display: flex;
  gap: 8px;
}

.landingPageButton.primary {
  color: var(--gray-1);
  border-color: var(--cyan-6);
  background-color: var(--cyan-6);
}

.landingPageButton.primary:hover {
  color: var(--gray-1);
  background-color: var(--cyan-7);
  border-color: var(--cyan-7);
}

.landingPageButton.primary:focus,
.landingPageButton.primary:active {
  color: var(--gray-1);
  background-color: var(--cyan-8);
  border-color: var(--cyan-8);
}

.landingPageButton.primary:focus-visible {
  color: var(--gray-1);
  background-color: var(--cyan-6);
  outline: 1px solid var(--cyan-6);
  box-shadow: 0 0 0 2px var(--gray-1), 0 0 0 4px var(--cyan-6);
}

.landingPageButton.secondary {
  color: var(--gray-1);
  border-color: var(--magenta-7);
  background-color: var(--magenta-7);
}

.landingPageButton.secondary:hover {
  color: var(--gray-1);
  border-color: var(--magenta-8);
  background-color: var(--magenta-8);
}

.landingPageButton.secondary:focus,
.landingPageButton.secondary:active {
  color: var(--gray-1);
  border-color: var(--magenta-9);
  background-color: var(--magenta-9);
}

.landingPageButton.secondary:focus-visible {
  color: var(--gray-1);
  border-color: var(--magenta-3);
  background-color: var(--magenta-3);
}

.landingPageButton.secondary:global(.ant-btn-background-ghost) {
  color: var(--magenta-3);
  border-color: var(--magenta-3);
  border-width: 1px;
  background-color: transparent;
}
.landingPageButton.secondary:global(.ant-btn-background-ghost):hover {
  color: var(--magenta-5);
  border-color: var(--magenta-5);
  background-color: transparent;
}

.landingPageButton.secondary:global(.ant-btn-background-ghost):focus,
.landingPageButton.secondary:global(.ant-btn-background-ghost):active {
  color: var(--magenta-3);
  border-color: var(--magenta-3);
  background-color: transparent;
}

.landingPageButton.secondary:global(.ant-btn-background-ghost):focus-visible {
  color: var(--magenta-3);
  border-color: var(--magenta-3);
  background-color: transparent;
}

@media (max-width: 900px) {
  .landingPageButton {
    justify-content: center;
    width: 400px;
    max-width: 400px;
  }
}
