.gradientHeader {
  position: relative;
  width: 100%;
}

.gradientHeader .banner {
  margin-left: 70px;
  margin-right: 38px;
  position: relative;
  z-index: 1;
  max-height: 562px;
  width: auto;
  height: 100%;
}

.gradientHeader .gradient {
  position: absolute;
  top: 54px;
  bottom: 0;
  left: 32px;
  right: 0;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  background: linear-gradient(
    56deg,
    var(--secondary-gradient-1) 0.64%,
    var(--secondary-gradient-2) 17.42%,
    var(--secondary-gradient-3) 39.14%,
    var(--secondary-gradient-4) 58.88%,
    var(--secondary-gradient-5) 76.65%,
    var(--secondary-gradient-6) 90.47%,
    var(--secondary-gradient-7) 99.36%
  );
  opacity: 0.75;
}

@media (max-width: 1200px) {
  .gradientHeader {
    margin-top: 25px;
  }

  .gradientHeader .banner {
    margin-left: 0;
  }

  .gradientHeader .gradient {
    left: 48px;
  }
}

@media (max-width: 900px) {
  .gradientHeader {
    overflow: hidden;
    position: absolute;
    opacity: 0.1;
  }

  .gradientHeader .banner {
    top: 0;
    margin: 0;
    z-index: 0;
  }

  .gradientHeader .gradient {
    display: none;
  }
}
