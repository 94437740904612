.footer {
  background: var(--primary-gradient);
  background: radial-gradient(
      50% 50% at 50% 50%,
      var(--primary-gradient) 0%,
      rgba(34, 43, 92, 0.6) 100%
    ),
    var(--primary-gradient);
  background-repeat: no-repeat;
  display: flex;
  align-items: end;
  justify-content: center;
  padding: 106px 24px 80px 24px;
}

.footer .contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1488px;
  width: 100%;
}

.footer .cards {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.footer .divider {
  border-top: 1px solid var(--geekblue-3);
  margin-top: 80px;
  margin-bottom: 24px;
}

.footer .divider.legals {
  margin-top: 24px;
}

.footer .legals {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media (max-width: 900px) {
  .footer .cards {
    flex-direction: column;
  }

  .footer .legals {
    text-align: center;
  }
}
